import {} from "@testing-library/react";
import React, { createContext, useEffect, useMemo, useState } from "react";

import { DefaultOmessage } from "./../controller/DefaultOmessageController.js";
import { ParentController } from "./../controller/ParentController.js";
import { PipeDriveSMS } from "./../controller/PipedriveController.js";
import { ZohoSupportDesk } from "./../controller/ZohodeskController.js";

import { ProviderCommonObj } from "../constants/ProviderConstants.js";
import { TeamsController } from "../controller/teamsController.js";
import ZohoCRMEmbedded from "../controller/ZohoCRMEmbeddedController.js";
import useHttp from "../hooks/useHttp.jsx";
import useServices from "../hooks/useServices";
import { Hubspot } from "./../controller/HubspotController";
import ZohoCRM from "./../controller/ZohoCRMController.js";
import { useScript } from "./../hooks/Hooks";
import Clarity from "@microsoft/clarity";

export const AppContext = createContext();
const parentControllerObj = Object.assign({}, ParentController);

export const ProviderObj = (props) => {
  const { fetchInstalledServices, getAllTextSMSServices } = useServices();
  const setLoading = props.setLoading;
  const setOtpModalState = props.setOtpModalState;
  const setPermissionDeniedToSubAccount = props.setPermissionDeniedToSubAccount;
  const setAppPropsObject = props.setAppPropsObject;
  const query = new URLSearchParams(window.location.search);
  const [appProperties, setAppProperties] = useState({});
  const [scriptUrl, setScriptUrl] = useState("");
  const [initFunctionCall, setInitFunctionCall] = useState("");
  const appPropertiesObject = useMemo(() => [appProperties, setAppProperties], [appProperties, setAppProperties]);
  
  let path = window.location.pathname;
  var serviceFromUrl = query.get("service");
  if (path === "/zoho-crm/webhook") {
    serviceFromUrl = "zohocrm";
  }

  const serviceName = serviceFromUrl != null ? serviceFromUrl : "default";

  const { fetchData } = useHttp();

  const [serviceObject, setServiceObj] = useState({});
  let appProps = {
    controller: serviceObject,
    setOtpModalState: setOtpModalState,
    setPermissionDeniedToSubAccount: setPermissionDeniedToSubAccount,
  };
  useEffect(() => {
    if (serviceName !== null && serviceName !== undefined) {
      let serviceObj = getController(serviceName);
      serviceObj.loadScripts().then((response) => {
        setScriptUrl(response);
      });
      setServiceObj(serviceObj);
      appProps = {
        controller: serviceObj,
        leftServiceName: serviceObj?.service,
      };
      setAppProperties((prev) => {
        return { ...prev, ...appProps };
      });
    }
  }, [serviceName]);

  const chargeBeeScript = useScript("https://js.chargebee.com/v2/chargebee.js");
  var scriptToBeLoaded = useScript(scriptUrl);

  useEffect(() => {
    if (serviceObject) {
      if (scriptUrl) {
        if (scriptToBeLoaded === "ready" && chargeBeeScript === "ready") {
          doInitializeChargebee();
          setInitFunctionCall("scripts-from-controller");
        }
      } else if (chargeBeeScript === "ready") {
        doInitializeChargebee();
        setInitFunctionCall("chargebee-script");
      }
    }
  }, [scriptToBeLoaded, chargeBeeScript, serviceObject]);

  useEffect(() => {
    var urlParams = new URLSearchParams(window.location.search);
    console.log("useEffect  INIT CALLED embeddedApp>>>>>>>>>>>>>> called", initFunctionCall);

    if (!isEmpty(serviceObject) && initFunctionCall) {
      serviceObject.init().then(function (data) {
        appProps.module = data?.module || data?.Entity;
        serviceObject.getUserData(true).then((extensionData) => {
          appProps.companyId = extensionData?.companyId;
          appProps.userId = extensionData?.userId;
          if (extensionData?.userHash) {
            appProps.userHash = extensionData?.userHash;
          }

          appProps.setLoading = setLoading;
          serviceObject.checkIsLicensed(fetchInstalledServices, appProps, getAllTextSMSServices).then(async function (response) {
            if (response) {
              const responseData = response?.data;
              appProps.hash = responseData?.hash;
              console.log("responseInteg", responseData);
              appProps.isParentOsyncAccount = false;
              let assignerObj1 = Object.assign(ProviderCommonObj);
              appProps.providerCommonObj = assignerObj1;
              const licenseObject = responseData?.license;
              const rolesBasedObject = responseData?.roles;
              appProps.rolesBasedObject = rolesBasedObject;
              appProps.licenseObj = licenseObject;
              if (responseData?.apps) {
                appProps.apps = responseData?.apps;
              }
              if (responseData?.leftServiceClientDetails) {
                appProps.leftServiceClientDetails = responseData?.leftServiceClientDetails;
                appProps.leftServiceId = appProps.leftServiceClientDetails?.serviceId;
              }

              // let integDetails = responses?.integrations
              // let integDetails = responseData?.integrations?.filter((item) => item?.integProps);
              const leftAuth = responseData?.left;
              if (leftAuth) appProps.authCRM = leftAuth?.auth?.authorized;
              let integrationDetails = responseData?.integrationDetails;
              console.log("responseInteg :::: integDetails", integrationDetails);

              let account = responseData?.accountDetails;

              appProps.licenseHandledBy = responseData?.accountDetails?.licenseHandledBy;
              // let account = responses?.accountDetails
              if (account) {
                appProps.accountStatus = account?.accountStatus;
                appProps.osyncId = account?.osyncId;
                appProps.hash = account?.hash;
                appProps.isParentOsyncAccount = account?.isParentHelloSendAccount === 1;
                appProps.agencyAccount = account?.agencyAccount === 1;
                appProps.extensionInstalledUserEmailId = account?.email;
              }

              try {
                var identify = appProps?.companyId + "_" + appProps?.userId;
                var displayName = "";
                if (integrationDetails?.userInfo) {
                  if (integrationDetails?.userInfo?.companyName) displayName = integrationDetails?.userInfo?.companyName.slice(0,4) + " <> ";
                  if (integrationDetails?.userInfo?.userName) displayName += integrationDetails?.userInfo?.userName.slice(0,4);
                }
                Clarity.identify(identify, appProps?.userId, window.location.pathname, displayName);
                if(appProps?.leftServiceClientDetails?.name) {
                  Clarity.setTag("leftService", appProps?.leftServiceClientDetails?.name);
                }
                Clarity.setTag("companyId", appProps?.companyId);
                Clarity.setTag("osyncId", appProps?.osyncId);
                Clarity.setTag("userId", appProps?.userId);
                if(appProps?.licenseObj?.licenseDetails?.licenseType === "user" && appProps?.licenseObj?.licenseDetails?.licenseStatus === 1){
                  Clarity.setTag("userType", "Paid");
                  Clarity.setTag("planName", appProps?.licenseObj?.licenseDetails?.planName);
                  Clarity.setTag("usersPurchased", appProps?.licenseObj?.licenseDetails?.totalUsersPurchased);
                } else {
                  Clarity.setTag("userType", "Trial");
                }
                console.log("Clarity init success>> ", identify, displayName);
              } catch (error) {
                console.log("Clarity init failed>> ", error);
              }

              appProps.hasPermissionToAccessMainAccount = responseData?.hasPermissionToAccessMainAccount;
              appProps.currentUserInstalledTheExtension = responseData?.currentUserInstalledTheExtension;
              console.log("responseInteg ddd appProps.isParentOsyncAccount", appProps.isParentOsyncAccount);

              if (responseData?.subAccounts) {
                appProps.subAccountsList = responseData?.subAccounts;
              }
              if (urlParams.has("osyncId") && urlParams.get("osyncId") === "" && !appProps?.hasPermissionToAccessMainAccount) {
                setPermissionDeniedToSubAccount(true);
                return;
              }
              if (window.self !== window.top && localStorage.getItem("recent-accessed-sub-account") && !urlParams.has("osyncId")) {
                reloadWindow(urlParams, localStorage.getItem("recent-accessed-sub-account"));
                return;
              }

              if (!appProps?.isParentOsyncAccount && appProps?.osyncId && !urlParams.has("osyncId")) {
                reloadWindow(urlParams, appProps?.osyncId);
                return;
              }

              let leftServiceDetails = responseData?.leftService;

              if (leftServiceDetails) {
                appProps.leftServiceName = leftServiceDetails?.name;
                appProps.leftServiceDisplayName = leftServiceDetails?.displayName;
              }
              let leftAuthDetails = responseData?.left;
              if (leftAuthDetails) {
                appProps.authCRM = leftAuthDetails?.auth?.authorized;
              }

              if (integrationDetails) {
                if (integrationDetails?.userInfo?.userId) {
                  appProps.userLogo = integrationDetails?.userInfo?.userLogo;
                  appProps.userId = integrationDetails?.userInfo?.userId ?? urlParams.get("userId");
                  appProps.userName = integrationDetails?.userInfo?.userName;
                  if (!appProps?.companyId) {
                    appProps.companyId = integrationDetails?.userInfo?.companyId;
                  }
                  appProps.currentUserEmail = integrationDetails?.userInfo?.userEmail;
                  appProps.emailId = integrationDetails?.email;
                  appProps.domain = integrationDetails?.domain;
                  appProps.isOmniAccount = integrationDetails?.omniAccount;
                  appProps.phone = integrationDetails?.phone;
                } else if (urlParams.get("userId")) {
                  appProps.userId = urlParams.get("userId");
                }

                if (integrationDetails?.leftServiceId) {
                  appProps.leftServiceId = integrationDetails?.leftServiceId;
                }
              }
              console.log(
                "::::::::hasAccessToParentAccount licenseObject?.allLicensedUserDetails 2 ::::::::::::",
                licenseObject?.allLicensedUserDetails
              );
              let hasAccessToCurrentSubAccount = false;

              if (licenseObject?.allLicensedUserDetails) {
                let userLicense = licenseObject?.allLicensedUserDetails.filter((item) => item.remoteUserId === appProps.userId);
                if (userLicense && userLicense.length > 0) {
                  hasAccessToCurrentSubAccount = true;
                }
              }
              if (!hasAccessToCurrentSubAccount) {
                setPermissionDeniedToSubAccount(true);
                return;
              }
              appProps.fetchData = fetchData;

              appProps.phoneList = true;

              const responseInteg = responseData?.integrations?.filter((item) => item?.integProps);

              if (responseInteg) {
                let installedServicesResp = response.data;

                let rightServices = installedServicesResp?.integrations.filter((obj) => obj?.integProps);
                if (rightServices?.length > 0) {
                  appProps.serviceInstalled = true;
                  appProps.installedApps = rightServices;
                  let randomIntegId = rightServices.filter((obj) => obj?.right?.service_name !== "hello_send")?.[0]?.integProps?.integId;
                  if (!randomIntegId) {
                    randomIntegId = rightServices?.[0]?.integProps?.integId;
                  }
                  appProps.randomIntegId = randomIntegId;
                } else {
                  appProps.serviceInstalled = false;
                }

                if (installedServicesResp) {
                  const licenseObject = installedServicesResp?.license;
                  appProps.licenseObj = licenseObject;
                  appProps.savedNumbers = installedServicesResp?.numbers;
                  appProps.allNumbers = installedServicesResp?.allNumbers;
                }

                if (responseInteg) {
                  for (const authorizeData of responseInteg) {
                    appProps.integId = authorizeData.integProps.integId;
                    let leftAuth = authorizeData?.left?.auth?.authorized;
                    appProps.authCRM = authorizeData?.left?.auth?.authorized;
                    if (!leftAuth) {
                      let leftServiceName = authorizeData?.left?.service_display_name;
                      let leftService = leftServiceName?.charAt(0)?.toUpperCase() + leftServiceName?.slice(1)?.toLowerCase();
                      appProps.authTitle = "Service not Authorized";
                      appProps.authContent = "Restore SMS channel access by authorizing " + leftService + ".";
                      appProps.authButton = "Authorize";
                      appProps.authLink = authorizeData?.left?.auth?.url;
                      appProps.blockApiCall = true;
                      appProps.authorizeObj = authorizeData;
                      break;
                    }
                    const allAuthorizedFalse = responseInteg.every((authorizeData) => !authorizeData?.right?.auth?.authorized);
                    if (allAuthorizedFalse) {
                      let rightAuth = authorizeData?.right?.auth?.authorized;
                      if (!rightAuth) {
                        appProps.authTitle = "Service not Authorized";
                        appProps.authContent = "Restore SMS channel access by authorizing SMS Channel.";
                        appProps.authButton = "Authorize";
                        appProps.authLink = authorizeData?.right?.auth?.url;
                        appProps.authorizeObj = authorizeData;
                        break;
                      }
                    }
                  }
                }
              } else {
                console.log("getting integrations error >>>>>>>>>>");
              }
              appProps.setLoading(false);
            }
            setAppProperties((prev) => {
              return { ...prev, ...appProps };
            });
            setAppPropsObject(appProps);
          });
        });
      });
    }
  }, [isEmpty(serviceObject), appProperties.RE_INIT, initFunctionCall]);

  return <AppContext.Provider value={appPropertiesObject}>{props.children}</AppContext.Provider>;
};

function doInitializeChargebee() {
  var siteAttribute = "oapps";
  var locationHref = window.location.origin;
  if (
    locationHref.indexOf("jupiter.rekzyl.com") !== -1 ||
    locationHref.indexOf("localhost") !== -1 ||
    locationHref.indexOf("client2.rekzyl.com") !== -1 ||
    locationHref.indexOf("client3.rekzyl.com") !== -1 ||
    locationHref.indexOf("client4.rekzyl.com") !== -1 ||
    locationHref.indexOf("client5.rekzyl.com") !== -1 ||
    locationHref.indexOf("client6.rekzyl.com") !== -1 ||
    locationHref.indexOf("pre-omessage.oapps.xyz") !== -1
  ) {
    siteAttribute = "oapps-test";
  }
  window.Chargebee.init({ site: siteAttribute });
  console.log("Chargebee script loaded successfully!!!! embeddedApp ");
  return { siteAttribute, locationHref };
}

export function getController(serviceName) {
  let serviceObj = {};
  console.log("serviceName >>>>>>>>>>>>>>>>>>>>>", serviceName);
  if (serviceName === "pdOmni") {
    serviceObj = Object.assign(parentControllerObj, PipeDriveSMS);
  } else if (serviceName === "zohodesk") {
    serviceObj = Object.assign(parentControllerObj, ZohoSupportDesk);
  } else if (serviceName === "default") {
    serviceObj = Object.assign(parentControllerObj, DefaultOmessage);
  } else if (serviceName === "hubspotcrm") {
    serviceObj = Object.assign(parentControllerObj, Hubspot);
  } else if (serviceName === "zohocrm" || serviceName === "zohocrmredirect") {
    serviceObj = Object.assign(parentControllerObj, ZohoCRM);
    if (serviceName === "zohocrm") {
      serviceObj = Object.assign(serviceObj, ZohoCRMEmbedded);
    }
  } else if (serviceName === "teams") {
    serviceObj = Object.assign(parentControllerObj, TeamsController);
  }
  return serviceObj;
}

function isEmpty(obj) {
  for (var x in obj) {
    return false;
  }
  return true;
}

function reloadWindow(urlParams, osyncId) {
  urlParams.append("osyncId", osyncId);
  var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + urlParams.toString();
  window.location.href = newUrl;
}
