import React, { useState, useEffect } from "react";
import "./index.css";
import { BrowserRouter as Router, RouterProvider } from "react-router-dom";

import { theme } from "antd";
import Pricingbar from "./components/main/pricingbar";
import { ProviderObj } from "./context/AppContext";
import AdminWrapper from "./components/main/AdminWrapper";
import { CommonLoadingV2 } from "./pages/utils/CommonVessels";
import * as Sentry from "@sentry/react";
import OTPModal from "./components/custom/OTPModal";
import PermissionDeniedModal from "./pages/utils/PermissionDeniedModal";
import createRouter from "./pages/router";
import Clarity from '@microsoft/clarity';

const App = () => {
  console.count("COMPONENT_RENDERED :: App");
  let pathName = window.location.pathname;
  const pathSegments = pathName.split("/");
  const [pricing] = useState(pathSegments?.indexOf("pricing") !== -1 ? true : false);
  const [permissionDeniedToSubAccount, setPermissionDeniedToSubAccount] = useState(false);
  const [admin] = useState(pathSegments?.indexOf("admin") !== -1 ? true : false);
  const [otpModalState, setOtpModalState] = useState(false);
  const [appPropsObject, setAppPropsObject] = useState({});
  console.log("Environment : ", process.env.NODE_ENV);
  console.log("Environment app : ", process.env.REACT_APP_ENV);

  const [loading, setLoading] = useState(pathSegments?.indexOf("content") !== -1 ? false : true);

  const loadingContextValue = {
    setLoading: setLoading,
    loading: loading,
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== "development") {
      const projectId = process.env.REACT_APP_ENV === "production" ? "nd80pjlpbf" : "pygp7sgnfn"
      Clarity.init(projectId);
      console.log("Clarity init load success : ", projectId);
      
      window.$sleek = [];
      window.SLEEK_PRODUCT_ID = process.env.REACT_APP_SLEEKPLAN_PRODUCT_ID;
      const sleekPlanScript = document?.createElement("script");
      sleekPlanScript.src = "https://client.sleekplan.com/sdk/e.js";
      sleekPlanScript.async = true;
      document?.body?.appendChild(sleekPlanScript);

      return () => {
        document.body.removeChild(sleekPlanScript);
      };
    }
  }, []);
  const router = createRouter(loadingContextValue);

  return (
    <>
      {pricing ? (
        <Router>
          <Pricingbar />
        </Router>
      ) : // Router><Admin/></Router>
      admin ? (
        <AdminWrapper />
      ) : (
        <>
          {otpModalState && (
            <OTPModal appPropsObject={appPropsObject} open={otpModalState} setLoading={setLoading} setOtpModalState={setOtpModalState} />
          )}
          <PermissionDeniedModal permissionDeniedToSubAccount={permissionDeniedToSubAccount} />
          <ProviderObj
            setLoading={setLoading}
            setOtpModalState={setOtpModalState}
            setPermissionDeniedToSubAccount={setPermissionDeniedToSubAccount}
            setAppPropsObject={setAppPropsObject}
          >
            {/* <Router> */}
            {loading ? <CommonLoadingV2 /> : null}
            {/* <Space
                direction="vertical"
                style={{ width: "100%", background: "none" }}
                size={[0, 48]}
              > */}

            {/* </Space> */}
            {/* </Router> */}
            <RouterProvider router={router} />
          </ProviderObj>
        </>
      )}
    </>
  );
};

if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: "https://9e6d564a937008aa6d41883bee1553f9@o4506630594363392.ingest.sentry.io/4506630597640192",
    environment: process.env.REACT_APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/omessage.oapps.xyz\.io\/api/],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
export default App;
